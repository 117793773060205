export default {
  '700 x 1000 mm': [
    {
      'preview': new URL('../assets/posters/700x1000-mm_01.jpg', import.meta.url),
      'thumbnail': new URL('../assets/posters/700x1000-mm_01.jpg?width=250', import.meta.url),
      'pdf': new URL('../assets/posters/700x1000-mm_01.pdf', import.meta.url),
      'filename': '700x1000-mm_01.pdf',
    },
    {
      'preview': new URL('../assets/posters/700x1000-mm_02.jpg', import.meta.url),
      'thumbnail': new URL('../assets/posters/700x1000-mm_02.jpg?width=250', import.meta.url),
      'pdf': new URL('../assets/posters/700x1000-mm_02.pdf', import.meta.url),
      'filename': '700x1000-mm_02.pdf',
    },
    {
      'preview': new URL('../assets/posters/700x1000-mm_03.jpg', import.meta.url),
      'thumbnail': new URL('../assets/posters/700x1000-mm_03.jpg?width=250', import.meta.url),
      'pdf': new URL('../assets/posters/700x1000-mm_03.pdf', import.meta.url),
      'filename': '700x1000-mm_03.pdf',
    },
    {
      'preview': new URL('../assets/posters/700x1000-mm_04.jpg', import.meta.url),
      'thumbnail': new URL('../assets/posters/700x1000-mm_04.jpg?width=250', import.meta.url),
      'pdf': new URL('../assets/posters/700x1000-mm_04.pdf', import.meta.url),
      'filename': '700x1000-mm_04.pdf',
    },
    {
      'preview': new URL('../assets/posters/700x1000-mm_05.jpg', import.meta.url),
      'thumbnail': new URL('../assets/posters/700x1000-mm_05.jpg?width=250', import.meta.url),
      'pdf': new URL('../assets/posters/700x1000-mm_05.pdf', import.meta.url),
      'filename': '700x1000-mm_05.pdf',
    },
    {
      'preview': new URL('../assets/posters/700x1000-mm_06.jpg', import.meta.url),
      'thumbnail': new URL('../assets/posters/700x1000-mm_06.jpg?width=250', import.meta.url),
      'pdf': new URL('../assets/posters/700x1000-mm_06.pdf', import.meta.url),
      'filename': '700x1000-mm_06.pdf',
    },
    {
      'preview': new URL('../assets/posters/700x1000-mm_07.jpg', import.meta.url),
      'thumbnail': new URL('../assets/posters/700x1000-mm_07.jpg?width=250', import.meta.url),
      'pdf': new URL('../assets/posters/700x1000-mm_07.pdf', import.meta.url),
      'filename': '700x1000-mm_07.pdf',
    },
    {
      'preview': new URL('../assets/posters/700x1000-mm_08.jpg', import.meta.url),
      'thumbnail': new URL('../assets/posters/700x1000-mm_08.jpg?width=250', import.meta.url),
      'pdf': new URL('../assets/posters/700x1000-mm_08.pdf', import.meta.url),
      'filename': '700x1000-mm_08.pdf',
    },
    {
      'preview': new URL('../assets/posters/700x1000-mm_09.jpg', import.meta.url),
      'thumbnail': new URL('../assets/posters/700x1000-mm_09.jpg?width=250', import.meta.url),
      'pdf': new URL('../assets/posters/700x1000-mm_09.pdf', import.meta.url),
      'filename': '700x1000-mm_09.pdf',
    },
    {
      'preview': new URL('../assets/posters/700x1000-mm_10.jpg', import.meta.url),
      'thumbnail': new URL('../assets/posters/700x1000-mm_10.jpg?width=250', import.meta.url),
      'pdf': new URL('../assets/posters/700x1000-mm_10.pdf', import.meta.url),
      'filename': '700x1000-mm_10.pdf',
    },
    {
      'preview': new URL('../assets/posters/700x1000-mm_11.jpg', import.meta.url),
      'thumbnail': new URL('../assets/posters/700x1000-mm_11.jpg?width=250', import.meta.url),
      'pdf': new URL('../assets/posters/700x1000-mm_11.pdf', import.meta.url),
      'filename': '700x1000-mm_11.pdf',
    },
    {
      'preview': new URL('../assets/posters/700x1000-mm_12.jpg', import.meta.url),
      'thumbnail': new URL('../assets/posters/700x1000-mm_12.jpg?width=250', import.meta.url),
      'pdf': new URL('../assets/posters/700x1000-mm_12.pdf', import.meta.url),
      'filename': '700x1000-mm_12.pdf',
    },
    {
      'preview': new URL('../assets/posters/700x1000-mm_13.jpg', import.meta.url),
      'thumbnail': new URL('../assets/posters/700x1000-mm_13.jpg?width=250', import.meta.url),
      'pdf': new URL('../assets/posters/700x1000-mm_13.pdf', import.meta.url),
      'filename': '700x1000-mm_13.pdf',
    },
    {
      'preview': new URL('../assets/posters/700x1000-mm_14.jpg', import.meta.url),
      'thumbnail': new URL('../assets/posters/700x1000-mm_14.jpg?width=250', import.meta.url),
      'pdf': new URL('../assets/posters/700x1000-mm_14.pdf', import.meta.url),
      'filename': '700x1000-mm_14.pdf',
    },
    {
      'preview': new URL('../assets/posters/700x1000-mm_15.jpg', import.meta.url),
      'thumbnail': new URL('../assets/posters/700x1000-mm_15.jpg?width=250', import.meta.url),
      'pdf': new URL('../assets/posters/700x1000-mm_15.pdf', import.meta.url),
      'filename': '700x1000-mm_15.pdf',
    },
    {
      'preview': new URL('../assets/posters/700x1000-mm_16.jpg', import.meta.url),
      'thumbnail': new URL('../assets/posters/700x1000-mm_16.jpg?width=250', import.meta.url),
      'pdf': new URL('../assets/posters/700x1000-mm_16.pdf', import.meta.url),
      'filename': '700x1000-mm_16.pdf',
    },
    {
      'preview': new URL('../assets/posters/700x1000-mm_17.jpg', import.meta.url),
      'thumbnail': new URL('../assets/posters/700x1000-mm_17.jpg?width=250', import.meta.url),
      'pdf': new URL('../assets/posters/700x1000-mm_17.pdf', import.meta.url),
      'filename': '700x1000-mm_17.pdf',
    },
  ],
  '28 x 40 inch': [
    {
      'preview': new URL('../assets/posters/28x40-inch_01.jpg', import.meta.url),
      'thumbnail': new URL('../assets/posters/28x40-inch_01.jpg?width=250', import.meta.url),
      'pdf': new URL('../assets/posters/28x40-inch_01.pdf', import.meta.url),
      'filename': '28x40-inch_01.pdf',
    },
    {
      'preview': new URL('../assets/posters/28x40-inch_02.jpg', import.meta.url),
      'thumbnail': new URL('../assets/posters/28x40-inch_02.jpg?width=250', import.meta.url),
      'pdf': new URL('../assets/posters/28x40-inch_02.pdf', import.meta.url),
      'filename': '28x40-inch_02.pdf',
    },
    {
      'preview': new URL('../assets/posters/28x40-inch_03.jpg', import.meta.url),
      'thumbnail': new URL('../assets/posters/28x40-inch_03.jpg?width=250', import.meta.url),
      'pdf': new URL('../assets/posters/28x40-inch_03.pdf', import.meta.url),
      'filename': '28x40-inch_03.pdf',
    },
    {
      'preview': new URL('../assets/posters/28x40-inch_04.jpg', import.meta.url),
      'thumbnail': new URL('../assets/posters/28x40-inch_04.jpg?width=250', import.meta.url),
      'pdf': new URL('../assets/posters/28x40-inch_04.pdf', import.meta.url),
      'filename': '28x40-inch_04.pdf',
    },
    {
      'preview': new URL('../assets/posters/28x40-inch_05.jpg', import.meta.url),
      'thumbnail': new URL('../assets/posters/28x40-inch_05.jpg?width=250', import.meta.url),
      'pdf': new URL('../assets/posters/28x40-inch_05.pdf', import.meta.url),
      'filename': '28x40-inch_05.pdf',
    },
    {
      'preview': new URL('../assets/posters/28x40-inch_06.jpg', import.meta.url),
      'thumbnail': new URL('../assets/posters/28x40-inch_06.jpg?width=250', import.meta.url),
      'pdf': new URL('../assets/posters/28x40-inch_06.pdf', import.meta.url),
      'filename': '28x40-inch_06.pdf',
    },
    {
      'preview': new URL('../assets/posters/28x40-inch_07.jpg', import.meta.url),
      'thumbnail': new URL('../assets/posters/28x40-inch_07.jpg?width=250', import.meta.url),
      'pdf': new URL('../assets/posters/28x40-inch_07.pdf', import.meta.url),
      'filename': '28x40-inch_07.pdf',
    },
    {
      'preview': new URL('../assets/posters/28x40-inch_08.jpg', import.meta.url),
      'thumbnail': new URL('../assets/posters/28x40-inch_08.jpg?width=250', import.meta.url),
      'pdf': new URL('../assets/posters/28x40-inch_08.pdf', import.meta.url),
      'filename': '28x40-inch_08.pdf',
    },
    {
      'preview': new URL('../assets/posters/28x40-inch_09.jpg', import.meta.url),
      'thumbnail': new URL('../assets/posters/28x40-inch_09.jpg?width=250', import.meta.url),
      'pdf': new URL('../assets/posters/28x40-inch_09.pdf', import.meta.url),
      'filename': '28x40-inch_09.pdf',
    },
    {
      'preview': new URL('../assets/posters/28x40-inch_10.jpg', import.meta.url),
      'thumbnail': new URL('../assets/posters/28x40-inch_10.jpg?width=250', import.meta.url),
      'pdf': new URL('../assets/posters/28x40-inch_10.pdf', import.meta.url),
      'filename': '28x40-inch_10.pdf',
    },
    {
      'preview': new URL('../assets/posters/28x40-inch_11.jpg', import.meta.url),
      'thumbnail': new URL('../assets/posters/28x40-inch_11.jpg?width=250', import.meta.url),
      'pdf': new URL('../assets/posters/28x40-inch_11.pdf', import.meta.url),
      'filename': '28x40-inch_11.pdf',
    },
    {
      'preview': new URL('../assets/posters/28x40-inch_12.jpg', import.meta.url),
      'thumbnail': new URL('../assets/posters/28x40-inch_12.jpg?width=250', import.meta.url),
      'pdf': new URL('../assets/posters/28x40-inch_12.pdf', import.meta.url),
      'filename': '28x40-inch_12.pdf',
    },
    {
      'preview': new URL('../assets/posters/28x40-inch_13.jpg', import.meta.url),
      'thumbnail': new URL('../assets/posters/28x40-inch_13.jpg?width=250', import.meta.url),
      'pdf': new URL('../assets/posters/28x40-inch_13.pdf', import.meta.url),
      'filename': '28x40-inch_13.pdf',
    },
    {
      'preview': new URL('../assets/posters/28x40-inch_14.jpg', import.meta.url),
      'thumbnail': new URL('../assets/posters/28x40-inch_14.jpg?width=250', import.meta.url),
      'pdf': new URL('../assets/posters/28x40-inch_14.pdf', import.meta.url),
      'filename': '28x40-inch_14.pdf',
    },
    {
      'preview': new URL('../assets/posters/28x40-inch_15.jpg', import.meta.url),
      'thumbnail': new URL('../assets/posters/28x40-inch_15.jpg?width=250', import.meta.url),
      'pdf': new URL('../assets/posters/28x40-inch_15.pdf', import.meta.url),
      'filename': '28x40-inch_15.pdf',
    },
    {
      'preview': new URL('../assets/posters/28x40-inch_16.jpg', import.meta.url),
      'thumbnail': new URL('../assets/posters/28x40-inch_16.jpg?width=250', import.meta.url),
      'pdf': new URL('../assets/posters/28x40-inch_16.pdf', import.meta.url),
      'filename': '28x40-inch_16.pdf',
    },
    {
      'preview': new URL('../assets/posters/28x40-inch_17.jpg', import.meta.url),
      'thumbnail': new URL('../assets/posters/28x40-inch_17.jpg?width=250', import.meta.url),
      'pdf': new URL('../assets/posters/28x40-inch_17.pdf', import.meta.url),
      'filename': '28x40-inch_17.pdf',
    },
  ],
};
